import { LanguageKeys } from 'lang';
import { isEmpty } from 'lodash';
import { IntlShape } from 'react-intl';
import { v4 } from 'uuid';
import { ChartFragment } from 'models/api-response';
import { GenderPatientContext } from 'models/enum';
import { PatientContext } from 'models/ui';
import { WOMAN_HEALTH_MIN_AGE_IN_DAYS } from 'constants/app.constant';
import { Box, Headline } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import MultiLineWithEmpty from 'components/features/chart/pre-clinical-manager/shared/MultiLineWithEmpty';
import { FormField } from './constants';
import ReadOnlyInput from '../shared/ReadOnlyInput';

export interface NutritionScreenReadOnlyProps {
  chartFragment: ChartFragment;
  statusFragment: ChartFragment;
  hideHeadline?: boolean;
  patientContext: PatientContext;
  intl: IntlShape;
}

interface Params {
  chartFragment: ChartFragment;
  sectionTitle: string;
}

export const renderReadOnlyScale = ({ chartFragment, sectionTitle }: Params) => {
  const section = chartFragment.chartData?.records?.find((item) => item.sectionTitle === sectionTitle);
  return section?.records
    ?.filter((item) => !isEmpty(item.content))
    .map((item) => {
      const nestFields = item.linkedFormFieldIds ? section.nestedFieldRecords?.filter((nestedFieldItem) => nestedFieldItem?.formField === item.linkedFormFieldIds[0]) : [];
      return (
        <Box key={item.id} pt pb>
          <h4>{item.title}</h4>
          <MultiLineWithEmpty text={item.content?.label} />
          {nestFields.length > 0 && nestFields.map((nestField) => <MultiLineWithEmpty key={v4()} text={nestField.value} />)}
        </Box>
      );
    });
};

const NutritionScreenReadOnly = (props: NutritionScreenReadOnlyProps) => {
  const { chartFragment, statusFragment, hideHeadline, patientContext, intl } = props;
  const isWomenHealth = patientContext?.ageInDays >= WOMAN_HEALTH_MIN_AGE_IN_DAYS && patientContext?.gender === GenderPatientContext.FEMALE;

  return (
    <Box mt2>
      {!hideHeadline && (
        <>
          <h1>Admission History</h1>
          <Headline statusFragment={statusFragment} chartingFragment={chartFragment} />
        </>
      )}
      <h2>Nutrition Screen</h2>
      <Box className="sng-article">
        {!chartFragment && <h3>Chart Omitted</h3>}
        {chartFragment && (
          <>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                {isWomenHealth && (
                  <>
                    <h3>{LanguageKeys.NUTRITION_SCREEN.INDICATORS_NUTRITIONAL_RISK_DURING_PREGNANCY}</h3>
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={LanguageKeys.NUTRITION_SCREEN.INDICATORS_NUTRITIONAL_RISK_DURING_PREGNANCY}
                      formFieldId={FormField.INDICATORS_NUTRITIONAL_RISK_DURING_PREGNANCY}
                      customTitle=""
                    />
                  </>
                )}
                <h3>{LanguageKeys.NUTRITION_SCREEN.GENERA_NUTRITION_SCREEN}</h3>
                {renderReadOnlyScale({ chartFragment, sectionTitle: LanguageKeys.NUTRITION_SCREEN.GENERA_NUTRITION_SCREEN })}
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{LanguageKeys.NUTRITION_SCREEN.ILLNESS_INJURY_NUTRITION_SCREEN}</h3>
                {renderReadOnlyScale({ chartFragment, sectionTitle: LanguageKeys.NUTRITION_SCREEN.ILLNESS_INJURY_NUTRITION_SCREEN })}
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{LanguageKeys.NUTRITION_SCREEN.FOOD_PREFERENCES_DIET_CONSIDERATIONS}</h3>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={LanguageKeys.NUTRITION_SCREEN.FOOD_PREFERENCES_DIET_CONSIDERATIONS}
                  formFieldId={FormField.FOOD_PREFERENCES_DIET_CONSIDERATIONS}
                  customTitle=""
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={LanguageKeys.NUTRITION_SCREEN.FOOD_PREFERENCES_DIET_CONSIDERATIONS}
                  formFieldId={FormField.OTHER_INPUT}
                  customTitle={LanguageKeys.OTHER}
                  notRenderBlankField
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={LanguageKeys.NUTRITION_SCREEN.FOOD_PREFERENCES}
                  formFieldId={FormField.FOOD_PREFERENCES}
                  customTitle={LanguageKeys.NUTRITION_SCREEN.FOOD_PREFERENCES}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={LanguageKeys.NUTRITION_SCREEN.DIET_BEFORE_HOSPITALIZATION_INCLUDING_SNACKS}
                  formFieldId={FormField.DIET_SNACK}
                  customTitle={intl.formatMessage({ id: LanguageKeys.NUTRITION_SCREEN.DIET_BEFORE_HOSPITALIZATION_INCLUDING_SNACKS })}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={LanguageKeys.NUTRITION_SCREEN.FOOD_DISLIKES}
                  formFieldId={FormField.FOOD_DISLIKES}
                  customTitle={LanguageKeys.NUTRITION_SCREEN.FOOD_DISLIKES}
                />
              </ELSFlexItem>
            </ELSFlex>
          </>
        )}
      </Box>
    </Box>
  );
};

NutritionScreenReadOnly.displayName = 'NutritionScreenReadOnly';
export default NutritionScreenReadOnly;
